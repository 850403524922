<template>
  <section id="hero">
    <h1 heavy>L'engagement, tout simplement</h1>
    <video
      ref="videoPlayerRef"
      poster="https://www.helloasso.com/public-documents/homepage/home-hero-poster.webp"
      preload="none"
      autoplay
      muted
      loop
      playsinline
      @timeupdate.once="markVideoAsLoaded"
    >
      <source
        src="https://www.helloasso.com/public-documents/homepage/home-hero.webm"
        type="video/webm"
      />
      <source
        src="https://www.helloasso.com/public-documents/homepage/home-hero-fallback.mp4"
        type="video/mp4"
      />
    </video>
    <template v-if="isVideoLoaded">
      <button
        v-if="isVideoPaused"
        type="button"
        @click="playVideo"
      >
        <HaIcon
          :icon="faPlay"
          aria-hidden="true"
          fixed-width
        />
        {{ $t('homeHero.play') }}
      </button>
      <button
        v-else
        type="button"
        @click="pauseVideo"
      >
        <HaIcon
          :icon="faPause"
          aria-hidden="true"
          fixed-width
        />
        {{ $t('homeHero.pause') }}
      </button>
    </template>
  </section>
</template>

<script setup lang="ts">
import { ref } from '#imports'
import { HaIcon } from '#components'
import { faPause, faPlay } from '@fortawesome/pro-solid-svg-icons'

const videoPlayerRef = ref()
const isVideoPaused = ref(false)
const isVideoLoaded = ref(false)

const markVideoAsLoaded = () => {
  isVideoLoaded.value = true
}

const pauseVideo = () => {
  videoPlayerRef.value.pause()
  isVideoPaused.value = true
}

const playVideo = () => {
  videoPlayerRef.value.play()
  isVideoPaused.value = false
}
</script>

<style lang="scss" scoped>
#hero {
  --title-font-size: 10dvw;
  --title-padding-top: 0;
  --title-padding-left: 0;

  position: relative;
  background-color: var(--hads-color-iris);

  @include mediaQuery(650) {
    background-image: url('@/assets/homepage/home-hero/optimized-logo.svg');
    background-repeat: no-repeat;
    background-size: 95%;
    background-blend-mode: screen;
    background-position: center;
  }

  h1,
  button {
    color: var(--hads-color-white);
  }

  h1 {
    position: relative;
    z-index: 2;
    width: min-content;
    margin: 0;
    line-height: 0.9;
    letter-spacing: -0.075em;
    text-transform: uppercase;
    font-size: var(--title-font-size);
    padding: var(--title-padding-top) 0 0 var(--title-padding-left);
  }

  video {
    position: absolute;
    object-fit: cover;
    mask-repeat: no-repeat;
    mask-size: cover;
  }

  button {
    position: absolute;
    bottom: 1em;
    right: 1em;
    cursor: pointer;
    font-weight: 600;
  }

  @include mediaQuery(650, 'max') {
    --title-padding-top: 2.8dvh;

    height: 100dvw;

    h1 {
      margin: 0 auto;
    }

    video {
      bottom: 0;
      mask-image: url('@/assets/homepage/home-hero/home-hero-mask-mobile.svg');
      width: 100%;
      aspect-ratio: 4 / 3;
    }
  }

  @include mediaQuery(650) {
    --title-font-size: 5.1dvw;
    --title-padding-left: var(--title-font-size);

    display: flex;
    align-items: center;
    height: min(40dvw, 70dvh);

    video {
      top: 0;
      right: 0;
      mask-image: url('@/assets/homepage/home-hero/home-hero-mask-desktop.svg');
      height: 100%;
      width: 70dvw;
      mask-position: bottom left;
    }
  }
}
</style>
